<template>
  <v-layout wrap style="padding: 10px">
    <v-flex xs12>
                  <v-text-field
                    v-model="token"
                    outlined
                    type="text"
                    v-on:keyup.enter="handleToken"
                  />
    </v-flex>
    <v-flex xs3>
      <v-btn @click="handleBack" block>ANT</v-btn>
    </v-flex>
    <v-flex xs6 style="padding: 0 20px;">
      <v-autocomplete
            v-model="selectedEnterprise"
            :items="enterprises"
            outlined
            dense
            chips
            small-chips
            label="Enterprises"
            item-value="ID"
            item-text="Name"
            @change="handleChangeSelectedEnterprise"
          />
    </v-flex>
    <v-flex xs3>
      <v-btn @click="handleNext" block>SIG</v-btn>
    </v-flex>
    <v-flex xs12>
      <center><b>{{selectedIndex}} / {{enterprises ? enterprises.length : ''}}</b></center>
    </v-flex>
    <v-flex xs12>
      <content-data 
        :enterpriseID="selectedEnterprise"
        :token="token"
      />
    </v-flex>
  </v-layout>
</template>
<script>
import axios from 'axios'
import ContentData from './Content'
export default {
  components: {
    ContentData,
  },
  data: () => ({
    token: null,
    selectedIndex: null,
    selectedEnterprise: null,
    enterprises: null,
  }),
  computed: {
  },
  mounted () {
    //this.getEnterprises()
  },
  methods: {
    handleToken () {
      if (!this.token) return
      this.getEnterprises()
    },
    getEnterprises () {
      axios.get(`https://www.dviewcontent.com/api/enterprises/-1/724?token=${this.token}`)
        .then(response => {
          if (response.data.error === true) {
            alert('error')
            return
          }
          this.enterprises = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    handleChangeSelectedEnterprise () {
      this.selectedIndex = this.enterprises.findIndex( x => x.ID === this.selectedEnterprise)
    },
    handleBack () {
      if (this.selectedIndex > 0) {
        this.selectedIndex--
        this.selectedEnterprise = this.enterprises[this.selectedIndex].ID
      }
    },
    handleNext () {
      if (this.selectedIndex < this.enterprises.length - 1) {
        this.selectedIndex++
        this.selectedEnterprise = this.enterprises[this.selectedIndex].ID
      }
    },
  },
}
</script>

